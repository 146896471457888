/*
*
* 세션 정보를 관리하는 영역
*
*/

// dao 쪽에 토큰 정보를 넘기기 위해, 토큰 정보만 공유될 수 있도록 쿠키에 처리한다. 
import { setCookie } from "./cookieManager";
import jwt_decode from "jwt-decode";

var commonSession = (function() {
  
    var getSession = function() {
        return {
            userId :  typeof window !== 'undefined' ? checkExpire(localStorage.getItem('userId'),'userId') : null,
            token : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('token'),'token') : null,
            nickName : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('nickName'),'nickName') : null,
            uid : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('uid'),'uid') : null,
            firstName : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('firstName'),'firstName') : null,
            lastName : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('lastName'),'lastName') : null,
            inviterEmail : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('inviterEmail'),'lastName') : null,
            enterprise : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('enterprise'),'enterprise') : null,
            certificated : typeof window !== 'undefined' ? checkExpire(localStorage.getItem('certificated'),'certificated') : null,
        }
    };

    var checkExpire = function(itemStr,key){

        if(!itemStr){
            return null;
        }

        const item = JSON.parse(itemStr);
        const now  = new Date();

        console.log(now.getTime());
        console.log(item.expiry);

        if(now.getTime() > item.expiry){
            localStorage.removeItem(key);
            return null;
        }

        return item.value;
    }

    var setSession = function(userIdValue ,tokenValue ,nickNameValue,uidValue,firstNameValue,lastNameValue,inviterEmail,enterprise,certificated) {
        const now  = new Date();
        var ttl = 86400000;

        userIdValue != null ? localStorage.setItem('userId', JSON.stringify({'value':userIdValue,'expiry':now.getTime() + ttl})) : '';
        tokenValue != null ? localStorage.setItem('token', JSON.stringify({'value':tokenValue,'expiry':now.getTime() + ttl})) : '';

        // dao 에서 로그인 정보 공유 처리 
        if(tokenValue != null){
            setCookie('token', tokenValue , {
                domain: '.mym-b.com' // <- 추가 된 부분
            });
        }

        nickNameValue != null ? localStorage.setItem('nickName', JSON.stringify({'value':nickNameValue,'expiry':now.getTime() + ttl})) : '';
        uidValue != null ? localStorage.setItem('uid', JSON.stringify({'value':uidValue,'expiry':now.getTime() + ttl})) : '';
        firstNameValue != null ? localStorage.setItem('firstName', JSON.stringify({'value':firstNameValue,'expiry':now.getTime() + ttl})) : '';
        lastNameValue != null ? localStorage.setItem('lastName', JSON.stringify({'value':lastNameValue,'expiry':now.getTime() + ttl})) : '';
        inviterEmail != null ? localStorage.setItem('inviterEmail', JSON.stringify({'value':inviterEmail,'expiry':now.getTime() + ttl})) : '';
        enterprise != null ? localStorage.setItem('enterprise', JSON.stringify({'value':enterprise,'expiry':now.getTime() + ttl})) : '';
        certificated != null ? localStorage.setItem('certificated', JSON.stringify({'value':certificated,'expiry':now.getTime() + ttl})) : '';
    };

    // 토큰 만료 체크 함수 추가
    var isTokenExpired = function() {
        const token = getSession().token;

        return false;
        console.log('token',token);
        console.log('jwt_decodetoken',jwt_decode(token));
        // if (!token) {
        //     // return true;
        // }

        // try {
        //     const decoded = jwt_decode(token);
        //     const currentTime = Date.now() / 1000; // 현재 시간을 초 단위로 변환

        //     console.log('decoded.exp',decoded.exp);
        //     console.log('currentTime',currentTime);
        //     // exp는 초 단위로 저장되어 있음
        //     if (decoded.exp < currentTime) {
        //         // 토큰이 만료되었으면 세션 정보 삭제
        //         localStorage.clear();
        //         return true;
        //     }
            
        //     return false;
        // } catch (error) {
        //     console.error("토큰 디코딩 에러:", error);
        //     // return true;
        // }
    };

    // 토큰 만료 시 로그인 페이지로 리다이렉트하는 함수 추가
    var checkTokenAndRedirect = function() {
        if (isTokenExpired()) {
            if (typeof window !== 'undefined') {
                alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
                window.location.href = "/login?returnUrl=" + encodeURIComponent(window.location.href);
            }
            return false;
        }
        return true;
    };
  
    return {
        getSession: getSession,
        setSession: setSession,
        isTokenExpired: isTokenExpired,
        checkTokenAndRedirect: checkTokenAndRedirect
    }
  
})();
  
export default commonSession;
