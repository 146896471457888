import React, { useEffect } from 'react'
import '../public/css/style.min.css'
import '../public/css/main.min.css'
import commonSession from "../common/commonSession"
import { useRouter } from 'next/router'

// 로그인이 필요하지 않은 페이지 목록
const PUBLIC_PATHS = [
  '/login',
  '/registration',
  '/registrationCorp',
  '/findPw',
  '/termsOfPrivacy',
  '/termsOfUse',
  '/termsOfUseCard',
  '/about',
  // ... 기타 public 페이지들
];

function Layout({ children }) {
  return <div className='layout'>{children}</div>
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    // 현재 경로가 public path가 아닐 경우에만 토큰 체크
    if (!PUBLIC_PATHS.includes(router.pathname)) {
      const checkAuth = () => {
        if (commonSession.isTokenExpired()) {
          alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
          router.push(`/login?returnUrl=${encodeURIComponent(router.asPath)}`);
        }
      };

      // 초기 로드 시 체크
      checkAuth();

      // 주기적으로 체크 (선택사항)
      const interval = setInterval(checkAuth, 60000); // 1분마다 체크

      return () => clearInterval(interval);
    }
  }, [router.pathname]);

  return (
    <Layout>
      <Component {...pageProps} />
    </Layout>
  );
}

export default MyApp;